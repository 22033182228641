import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UserSidebar from "../UserSidebar";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { RotatingLines } from "react-loader-spinner";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";

import Switch from "@mui/material/Switch";
import { imagePath } from "../ImageVariableGlobal/ImageVariableGlobal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Category Name",
  },
  {
    label: "Image",
  },
  {
    label: "Language",
  },
  {
    label: "Creat At",
  },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);
  let cookies = new Cookies();
  let navigate = useNavigate();
  // let navigate = useNavigate();

  const [accessType, setAccessType] = React.useState(null);

  React.useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setAccessType(decodedToken);
      } catch (error) {
        // Handle error if token decoding fails
        console.error("Failed to decode token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row._id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.machineTypeId}
        selected={isItemSelected}
      >
        <TableCell align="center" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>

        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="center">{row.category_name}</TableCell>

        <TableCell align="center">
          <img
            src={imagePath + row.a4_data_image}
            alt={row.a4_data_image}
            width="50px"
            height="50px"
          />
        </TableCell>

        <TableCell align="center">{row.languageName}</TableCell>

        <TableCell align="center">
          {moment(row.createdAt).format("DD-MM-YYYY")}
        </TableCell>

        <TableCell align="center">
          <button class="btn btn-default" onClick={() => seletedEditData(row)}>
            <EditIcon />
          </button>
        </TableCell>
      </TableRow>
      <TableRow></TableRow>
    </React.Fragment>
  );
}

export default function A4Item() {
  let navigate = useNavigate();

  const [image, setImage] = useState("");
  const [compImage, setCompImage] = useState("");
  const [imgLoader, setImgLoader] = useState(false);

  // Single Image Uplode
  const fileData = (file) => {
    setImgLoader(true);
    const dataArray = new FormData();
    dataArray.append("b_video", file);
    let url = "https://cdn.brandingprofitable.com/image_upload.php/";
    axios
      .post(url, dataArray, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImgLoader(false);
        const imagePath = res?.data?.iamge_path; // Correct the key to "iamge_path"
        const copressedImagePath = res?.data?.comp_iamge_path;
        setCompImage(copressedImagePath);
        setImage(imagePath);
      })
      .catch((err) => {
        setImgLoader(false);
        console.log("Error uploading image:", err);
      });
  };

  // Multiple Image Uplode
  const [multiImage, setMultiImage] = useState();
  const [multipleCompressedImage, setmultipleCompressedImage] = useState();
  const fileDataMultiple = (files) => {
    const maxImages = 25;

    if (files.length > maxImages) {
      swal("", `You can select a maximum of ${maxImages} images.`, "error");
      return;
    }

    setImgLoader(true);

    const imagesPath = [];
    const compImage = [];

    const url = "https://cdn.brandingprofitable.com/image_upload.php/";

    const uploadNextImage = (index) => {
      if (index >= files.length) {
        // All images uploaded
        setImgLoader(false);
        setMultiImage(imagesPath);
        setmultipleCompressedImage(compImage);
        return;
      }

      const file = files[index];
      const dataArray = new FormData();
      dataArray.append("b_video", file);

      axios
        .post(url, dataArray, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const imagePath = res?.data?.iamge_path; // Correct the key to "imagePath"
          const compImagePath = res?.data?.comp_iamge_path;
          imagesPath.push(imagePath);
          compImage.push(compImagePath);
          uploadNextImage(index + 1);
        })
        .catch((err) => {
          console.log("Error uploading image:", err);
          uploadNextImage(index + 1);
        });
    };

    // Start uploading the first image
    uploadNextImage(0);
  };

  const [getA4Data, setGetA4Data] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);
  const [totalImage, setTotalImage] = useState();

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getData = async () => {
    try {
      const res = await axios.get(`https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4data`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
        },
      });
      setLoader(false);
      setGetA4Data(res.data.data);
      setCountData(res.data.DynamicSection_ItemCount);
      setTotalImage(res.data.DynamicSection_ItemCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = getA4Data.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        axios
          .post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4data/a4data/delete/", selected)
          .then((response) => {
            console.log(response.data, "response");
            if (response.data.statusCode === 200) {
              getData();
              setSelected([]);
              swal("", response.data.message, "success");
            }
          });
      }
    });
  };

  //
  // Searchbar
  let handleSearchData = async (values) => {
    let res = await axios.post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/ds_item/search_ds_item", {
      search: values,
    });
    if (res.data.statusCode === 200) {
      if (values !== "") {
        setGetA4Data(res.data.data);
        setCountData(res.data.count);
      } else {
        getData();
      }
    }
  };

  var handleSubmitMultiImage = async (values) => {
    values["ds_itemImage"] = multiImage;
    values["comp_iamge"] = multipleCompressedImage;
    values["createDate"] = moment(new Date()).format("DD-MM-YYYY");
    values["createTime"] = moment(new Date()).format("HH:mm:ss");
    values["updateDate"] = moment(new Date()).format("DD-MM-YYYY");
    values["addDate"] = moment()
      .add(2, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");

    const imageUrls = Array.isArray(values.ds_itemImage)
      ? values.ds_itemImage
      : [values.ds_itemImage];

    const imageUrlsCompresse = Array.isArray(values.comp_iamge)
      ? values.comp_iamge
      : [values.comp_iamge];

    let successShown = false;

    for (let i = 0; i < imageUrls.length; i++) {
      // Extract the filename from the URL
      // const url = new URL(imageUrl);
      // const filename = url.pathname.split("/").pop();

      const dataToSend = {
        ds_category: values.ds_category,
        ds_itemImage: imageUrls[i], // Store only the filename
        comp_iamge: imageUrlsCompresse[i],
        ds_itemSwitch: values.ds_itemSwitch,
        languageName: values.languageName,
        isVideo: values.isVideo,
        createDate: values.createDate,
        createTime: values.createTime,
        updateDate: values.updateDate,
        addDate: values.addDate,
      };

      try {
        const res = await axios.post(
          "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/ds_item/ds_item",
          dataToSend
        );

        if (res.data.statusCode === 200 && !successShown) {
          setMultipleDataSendModal(false);
          // swal("", res.data.message, "success");
          swal("", res.data.message, "success");
          successShown = true;
        } else if (!successShown) {
          swal("", res.data.message, "error");
          successShown = true; // Set to true after showing the error message
        }
      } catch (error) {
        console.error("Error:", error);
        swal("Error", "An error occurred while saving data.", "error");
      }
    }

    setMultipleDataSendModal(false);
    getData();
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [multipleDataSendModal, setMultipleDataSendModal] = useState(false);
  let [id, setId] = React.useState();
  if (!id) {
    var handleSubmit = async (values) => {
      values["a4_data_image"] = image;
      values["comp_iamge"] = compImage;
      let res = await axios.post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4data", values);
      if (res.data.statusCode === 200) {
        setModalShowForPopupForm(false);
        getData();
        swal("", res.data.message, "success");
      } else {
        swal("", res.data.message, "error");
      }
    };
  } else {
    handleSubmit = async (values) => {
      values["a4_data_image"] = image;
      values["comp_iamge"] = compImage;
      let response = await axios.put(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4data/a4data/" + id,
        values
      );

      if (response.data.statusCode === 200) {
        setModalShowForPopupForm(false);
        getData();
        swal("", response.data.message, "success");
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas.a4_data_id);
    setEditData(datas);
    setImage(datas.a4_data_image);
  };

  // Formik
  let [dynamicSectionDataFormik, setDynamicSectionDataFormik] = useState({});
  const FormikValues = () => {
    const formik = useFormikContext();
    React.useEffect(() => {
      setDynamicSectionDataFormik(formik.values);
    }, [formik.values]);
    return null;
  };

  const [accessType, setAccessType] = React.useState(null);
  let cookies = new Cookies();

  React.useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setAccessType(decodedToken);
      } catch (error) {
        // Handle error if token decoding fails
        console.error("Failed to decode token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [a4category, setA4Category] = useState();

  let getCategoryNameData = async () => {
    let responce = await axios.get(
      "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4category/get/category"
    );
    setA4Category(responce.data.data);
  };

  React.useEffect(() => {
    getCategoryNameData();
  }, []);

  let [getLanguageName, setGetLanguageName] = useState();

  let getLanguageNameData = async () => {
    const token = cookies.get("token");
    let responce = await axios.get("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/languages", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setGetLanguageName(responce.data.data);
  };

  React.useEffect(() => {
    getLanguageNameData();
  }, []);

  // -----------------Dropdawn Logic---------------------------------------

  let [searchData, setSearchData] = useState({ categoryName: "" });
  const [filterLoader, setFilterLoader] = useState(false);
  const updateInputs = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSearchData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  var categoryFilter = async () => {
    try {
      setFilterLoader(true);
      let response = await axios.post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/a4data/filter", {
        category_id: searchData.category_id,
      });
      if (response.data.statusCode === 200) {
        setFilterLoader(false);
        setGetA4Data(response.data.data);
        setCountData(response.data.totalCount);
      }
    } catch (error) {
      setFilterLoader(false);
      console.error("Error fetching filtered data:", error);
    }
  };

  React.useEffect(() => {
    if (searchData) {
      categoryFilter();
    }
  }, [searchData]);

  // ----------------------------------------------------------------------

  return (
    <>
      <UserSidebar />

      <Box sx={{ width: "100%", pb: "2%", pl: "2%", pr: "2%" }}>
        <div id="main-btn-add-machinetype">
          <div className="d-flex flex-row justify-content-end mb-2 gap-2">
            <Button
              className="text-capitalize"
              size="small"
              onClick={() => {
                setModalShowForPopupForm(true);
                setId(null);
                setEditData({});
                setImage({});
                setSelectedCategoryName({});
              }}
            >
              Add A4 Data
            </Button>

            {/* <Button
              className="text-capitalize"
              size="small"
              onClick={() => {
                setMultipleDataSendModal(true);
                setId(null);
                setEditData({});
                setImage({});
                setSelectedCategoryName({});
              }}
            >
              Add Multiple
            </Button> */}
          </div>
        </div>

        <div className="d-flex justify-content-start">
          <div>Total Image: {totalImage}</div>
        </div>

        <Paper sx={{ width: "100%" }} className="table_bg_img">
          <Toolbar
            className="border-top border-bottom"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                A4 Section
              </Typography>
            )}
            {/* 
            <div className="d-flex gap-2 m-2">
              <TextField
                value={data.startingDate}
                onChange={updateDateInputs}
                type="date"
                size="small"
                label="Starting Date"
                name="startingDate"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                value={data.endingDate}
                onChange={updateDateInputs}
                type="date"
                size="small"
                name="endingDate"
                label="Ending Date"
                InputLabelProps={{
                  shrink: true,
                }}
              />


              <Button
                onClick={() => {
                  getData();
                  setData({
                    startingDate: "",
                    endingDate: "",
                  });
                }}
              >
                Reset
              </Button>
            </div> */}

            <div className="d-flex gap-3">
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel>Select Category</InputLabel>
                <Select
                  label="Select Category"
                  name="category_id"
                  value={searchData?.category_id}
                  onChange={updateInputs}
                  MenuProps={{
                    style: {
                      maxHeight: 210,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      getData();
                      setSearchData({
                        category_id: "",
                      });
                    }}
                  >
                    All
                  </MenuItem>
                  {a4category?.map((e, i) => {
                    return (
                      <MenuItem key={i} value={e.category_id}>
                        {e.category_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {/* <form className="form-inline">
                <input
                  id="serchbar-size"
                  className="form-control mr-sm-2"
                  type="search"
                  onChange={(e) => handleSearchData(e.target.value)}
                  placeholder="Search"
                  aria-label="Search"
                />
              </form> */}
            </div>

            {/* {accessType &&
              (() => {
                if (!accessType.includes("Allow To Delete")) {
                  return null;
                }
                return ( */}
            <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
            {/* );
              })()} */}
          </Toolbar>
          {loader || filterLoader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader || filterLoader}
              />
            </div>
          ) : (
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < getA4Data?.length
                        }
                        checked={
                          getA4Data?.length > 0 &&
                          selected.length === getA4Data?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>

                    {headCells.map((headCell, id) => {
                      return (
                        <TableCell key={id} className="fw-bold" align="center">
                          {headCell.label}
                        </TableCell>
                      );
                    })}

                    <TableCell className="fw-bold" align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getA4Data?.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Row
                        key={row.machineTypeId}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        selected={selected}
                        index={index}
                        seletedEditData={seletedEditData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={countData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </Box>
      <Dialog
        fullWidth
        open={modalShowForPopupForm}
        onClose={() => setModalShowForPopupForm(false)}
      >
        <DialogTitle>{"A4 Section"}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              category_id:
                editData && editData.category_id ? editData.category_id : "",
              languageName:
                editData && editData.languageName ? editData.languageName : "",
            }}
            validationSchema={Yup.object().shape({
              category_id: Yup.string().required("Required"),
              languageName: Yup.string().required("Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm(values);
            }}
          >
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <Form>
                {/* <FormikValues /> */}
                <div>
                  <div className="w-100 mb-3 mt-3">
                    <TextField
                      fullWidth
                      size="small"
                      select
                      label="Select Category"
                      name="category_id"
                      value={values.category_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {a4category.map((category) => (
                        <MenuItem
                          key={category._id}
                          value={category.category_id}
                        >
                          {category.category_name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {touched.category_id && errors.category_id ? (
                      <div className="text-danger">{errors.category_id}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 mt-3">
                    <label>Image</label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => fileData(e.target.files[0])}
                    />
                    <div className="text-danger">
                      {!id || imgLoader === false
                        ? null
                        : "Please Select Image again"}
                    </div>
                  </div>

                  {image && ( // Display the image if image URL is available
                    <div>
                      <img
                        src={imagePath + image} // Use the image state value here
                        alt="Image"
                        width="50px"
                        height="50px"
                      />
                    </div>
                  )}

                  <div className="mt-3">
                    <FormControl fullWidth>
                      <InputLabel size="small">Select Language</InputLabel>
                      <Select
                        size="small"
                        label="Select Language"
                        name="languageName"
                        value={values.languageName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={{
                          style: {
                            maxHeight: 210,
                          },
                        }}
                      >
                        {getLanguageName?.map((option) => (
                          <MenuItem
                            key={option?.languageName}
                            value={option?.languageName}
                          >
                            {option.languageName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.languageName && errors.languageName ? (
                        <div className="text-danger">{errors.languageName}</div>
                      ) : null}
                    </FormControl>
                  </div>

                  <div className="mt-3">
                    {!id ? (
                      <>
                        {imgLoader ? (
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary float-right"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {imgLoader ? (
                          <button
                            className="btn btn-warning"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-warning">
                            Update
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {/* ======================================================== */}
    </>
  );
}
