import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "react-bootstrap";
import { useState } from "react";
import swal from "sweetalert";

import Collapse from "@mui/material/Collapse";
import Cookies from "universal-cookie";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import UserSidebar from "../UserSidebar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import { Badge } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Full Name",
  },
  {
    label: "Plan",
  },

  {
    label: "Refer Details",
  },

  {
    label: "Pay Amount",
  },
  {
    label: "Reffer Amount",
  },
  {
    label: "New/Upgrate",
  },
  {
    label: "Purcahse Date",
  },
  {
    label: "Message",
  },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow
        hover
        // onClick={(event) => handleClick(event, row._id)}
        // onClick={() => navigate(`/referral-history/${row.mobileNumber}`)}
        style={{ cursor: "pointer" }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.machineTypeId}
        selected={isItemSelected}
        F
      >
        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="center">
          {row?.user_data?.fullName} <br /> {row?.mobileNumber}{" "}
        </TableCell>

        <TableCell align="center">
          {row?.plan_data?.plan_name} ({row?.plan_data?.plan_price})
        </TableCell>

        <TableCell align="center">
          {row?.refer_id !== "" ? row?.refer_user_data?.fullName : "-"} <br />
          {row?.refer_id !== "" ? row?.refer_id : "-"}
        </TableCell>
        <TableCell align="center">{row?.pay_amount}</TableCell>
        <TableCell align="center" className="text-danger">
          {row?.refer_amount}
        </TableCell>
        <TableCell align="center">
          {row?.is_upgrate ? (
            <span className="badge badge-warning bg-warning">Upgrade</span>
          ) : (
            <span className="badge badge-success bg-success">New</span>
          )}
        </TableCell>
        <TableCell align="center">{row?.updatedAt}</TableCell>
        <TableCell align="center">{row?.message}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TotalOutgoingBalance() {
  let cookies = new Cookies();
  const { user_id } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    const userData = cookies.get("token");

    if (!userData) {
      navigate("/superadmin/sign-in");
    }
  }, [cookies]);

  const [totalOutgoingBalance, setTotalOutgoingBalance] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalOutgoing, setTotalOutgoing] = useState(0);
  const getData = async () => {
    try {
      const res = await axios.get(`https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/abcd/total-outgoing`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
        },
      });
      setLoader(false);
      setTotalOutgoingBalance(res.data.data);
      setTotalOutgoing(res.data.referAmountSum);
      setCountData(res.data.count); // Make sure to adjust the key here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = totalOutgoingBalance?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        const token = cookies.get("token");
        axios
          .delete("/revenue/revenue", {
            data: selected,
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              getData();
              setSelected([]);
              swal(response.data?.message);
            }
          });
      }
    });
  };

  // Searchbar
  const [searchLoader, setSearchLoader] = useState(false);
  let handleSearchData = async (values) => {
    setSearchLoader(true);
    try {
      let res = await axios.post(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/abcd/totaljoin/search",
        {
          search: values,
        }
      );

      if (res.data.statusCode === 200) {
        if (values !== "") {
          setSearchLoader(false);
          setTotalOutgoingBalance(res.data.data);
          setCountData(res.data.count);
        } else if (values === "") {
          setSearchLoader(false);
          getData();
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setSearchLoader(false);
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      try {
        const res = await axios.post("/revenue/revenue", values);

        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          swal(res.data?.message);
        } else {
          swal(res.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        swal(error);
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await axios.put(
          `/revenue/revenue/${id}`, // Use template literals to include the id
          values
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          swal(response.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        swal(error);
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };

  // -------------- filter (new/upgrate)-----------------------------------

  // const [searchData, setSearchData] = useState({ is_upgrate: null });

  // const updateInputs = (e) => {
  //   const { name, value } = e.target;
  //   setSearchData((prevState) => ({
  //     ...prevState,
  //     [name]: value === "" ? null : value === "true",
  //   }));
  //   console.log(name, value, "name, value");
  // };

  // var filterData = async () => {
  //   let response = await axios.post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/abcd/filter", {
  //     is_upgrate: searchData.is_upgrate,
  //   });

  //   if (response.data.statusCode === 200) {
  //     setTotalOutgoingBalance(response.data.data);
  //     setCountData(response.data.count);
  //   }
  // };
  // React.useEffect(() => {
  //   if (searchData) {
  //     filterData();
  //   }
  // }, [searchData]);
  return (
    <>
      <UserSidebar />
      <div className="signup-container shadow p-3 rounded">
        <div id="main-btn-add-machinetype">
          <Paper
            sx={{
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Toolbar
              className="border-top border-bottom"
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                bgcolor: "#1976d2", // Set the background color here
                color: "white", // Set the font color to white
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Total Outgoing Balance(ABCD) : {totalOutgoing}
              </Typography>
              {/* )} */}

              <div className="d-flex gap-3">
                {/* <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel>Select Status</InputLabel>
                  <Select
                    style={{ backgroundColor: "white" }}
                    label="is_upgrate"
                    name="is_upgrate"
                    value={searchData?.is_upgrate}
                    onChange={updateInputs}
                    MenuProps={{
                      style: {
                        maxHeight: 210,
                      },
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={"true"}>Upgrade</MenuItem>
                    <MenuItem value={"false"}>New</MenuItem>
                  </Select>
                </FormControl> */}

                <form className="form-inline">
                  <input
                    id="serchbar-size"
                    className="form-control mr-sm-2"
                    type="search"
                    onChange={(e) => handleSearchData(e.target.value)}
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
              </div>

              <>
                {selected.length > 0 ? (
                  <Tooltip title="Delete">
                    {/* <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton> */}
                  </Tooltip>
                ) : null}
              </>
            </Toolbar>
            {loader || searchLoader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <Circles
                  height="50"
                  width="50"
                  color="#4A5073"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell, id) => {
                        return (
                          <TableCell
                            key={id}
                            className="fw-bold"
                            align="center"
                          >
                            {headCell.label}
                          </TableCell>
                        );
                      })}

                      {/* <TableCell className="fw-bold" align="center">
                      Action
                    </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {totalOutgoingBalance?.map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Row
                          key={row.machineTypeId}
                          row={row}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          handleClick={handleClick}
                          selected={selected}
                          index={index}
                          seletedEditData={seletedEditData}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={countData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
}
