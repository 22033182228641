import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UserSidebar from "../UserSidebar";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { RotatingLines } from "react-loader-spinner";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import FalseIcon from "../logo/error.png";
import TrueIcon from "../logo/accept.png";

import Switch from "@mui/material/Switch";
import { imagePath } from "../ImageVariableGlobal/ImageVariableGlobal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Image",
  },
  {
    label: "Full Name",
  },
  {
    label: "Number",
  },
  {
    label: "Reffaral Id",
  },
  {
    label: "Parent Id",
  },
  {
    label: "Side",
  },

  {
    label: "Status",
  },
  {
    label: "Approval",
  },
];

export default function MlmRegister() {
  let navigate = useNavigate();

  const [image, setImage] = useState("");
  const [imgLoader, setImgLoader] = useState(false);

  const fileData = (file) => {
    setImgLoader(true);
    const dataArray = new FormData();
    dataArray.append("b_video", file);
    let url = "https://cdn.brandingprofitable.com/image_upload.php/";
    axios
      .post(url, dataArray, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImgLoader(false);
        const imagePath = res?.data?.iamge_path; // Correct the key to "iamge_path"
        setImage(imagePath);
      })
      .catch((err) => {
        setImgLoader(false);
        console.log("Error uploading image:", err);
      });
  };

  //   let [getCategoryData, setGetCategoryData] = useState([]);
  let [addLanguage, setAddLanguage] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getData = async () => {
    try {
      const res = await axios.get(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/mlm/payment_check",
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
          },
        }
      );
      setLoader(false);
      setAddLanguage(res.data.data);
      setCountData(res.data.MlmPendingCount); // Make sure to adjust the key here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  function Row(props) {
    const { row, handleClick, isItemSelected, labelId, seletedEditData } =
      props;
    const [open, setOpen] = React.useState(false);
    let cookies = new Cookies();
    let navigate = useNavigate();
    // let navigate = useNavigate();

    // Check Authe(token)
    let chackAuth = async () => {
      if (cookies.get("token")) {
        let config = {
          headers: {
            token: cookies.get("token"),
          },
        };
        // auth post method
        let res = await axios.post(
          "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/admin/auth",
          { purpose: "validate access" },
          config
        );
        if (res.data.statusCode !== 200) {
          cookies.remove("token");
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };

    React.useEffect(() => {
      // chackAuth();
    }, [cookies.get("token")]);

    const [accessType, setAccessType] = React.useState(null);

    React.useEffect(() => {
      const token = cookies.get("token");
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          setAccessType(decodedToken);
        } catch (error) {
          // Handle error if token decoding fails
          console.error("Failed to decode token:", error);
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }, [navigate]);

    let handleSubmitCancel = async (id) => {
      let response = await axios.put(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/payment/payment/" +
          id,
        { isPayment: true }
      );
      if (response.data.statusCode === 200) {
        getData();
      }
    };

    let [modelShow, setModelShow] = React.useState(false);
    const [showDeniedReason, setShowDeniedReason] = useState(false);
    const [deniedReason, setDeniedReason] = useState("");
    // const [loading, setLoading] = useState(false);

    const handleKycSubmit = async (mobileNumber, status) => {
      try {
        // setLoading(true); 
        // Create a data object to send to the API
        const data = { status: status };

        if (status === "Reject" && deniedReason) {
          data.deniedReason = deniedReason;
        }
        // data.employeeRegisterDate 
        //   .add(1, "seconds")
        //   .format("YYYY-MM-DD HH:mm:ss");
        const response = await axios.put(
          `https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/mlm/payment_check/${mobileNumber}`,
          data
        );

        if (response.data.statusCode === 200) {
          console.log(response.data, "abc");
          setModelShow(false);
          window.location.reload();
          swal("", response.data.message, "success");
        }
      } catch (error) {
        console.error("Error:", error);
        swal("", "An error occurred while processing your request.", "error");
      }
      // finally {
      //   setLoading(false); // Set loading to false after the request is complete
      // }
    };

    return (
      <React.Fragment>
        <TableRow
          hover
          //   onClick={(event) => handleClick(event, row._id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.machineTypeId}
          selected={isItemSelected}
        >
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          {/* <TableCell align="center" padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell> */}

          {/* <TableCell align="center">{ row + 1}</TableCell> */}

          <TableCell align="center">
            <a href={row.image} target="_blank" rel="noopener noreferrer">
              <img src={row.image} alt={row.image} width="50px" height="50px" />
            </a>
          </TableCell>

          <TableCell align="center">{row.fullName}</TableCell>
          {/* <TableCell align="center">{row.bannerImage}</TableCell> */}
          <TableCell align="center">{row.mobileNumber}</TableCell>
          <TableCell align="center">{row.referredBy}</TableCell>
          <TableCell align="center">{row.treeId}</TableCell>
          <TableCell align="center">{row.side}</TableCell>
          <TableCell align="center">{row.status}</TableCell>

          <TableCell align="center">
            {row.status.includes("Complete") ? (
              <img src={TrueIcon} alt="Approve" />
            ) : // <FalseIcon />
            row.status.includes("Reject") ? (
              <img src={FalseIcon} alt="Reject" />
            ) : (
              <Button
                onClick={() => {
                  setModelShow(true);
                }}
              >
                Approval
              </Button>
            )}
          </TableCell>
        </TableRow>

        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={modelShow}
          onClose={() => setModelShow(false)}
        >
          <DialogTitle>
            {"KYC Status"}
            <IconButton
              aria-label="close"
              onClick={() => setModelShow(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              {/* <CloseIcon /> */}
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableCell align="center">
                <div className="d-flex justify-content-center gap-3">
                  {showDeniedReason ? (
                    <div className="mt-3">
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        placeholder="Denied Reason"
                        label="Denied Reason"
                        name="deniedReason"
                        value={deniedReason}
                        onChange={(e) => setDeniedReason(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center gap-3">
                      <div>
                        <Button
                          onClick={() =>
                            handleKycSubmit(row.mobileNumber, "Complete")
                          }
                          // disabled={loading}
                        >
                           {/* {loading ? 'Approving...' : 'Approval'} */}
                          Approval
                        </Button>
                      </div>
                      <div>
                        <Button 
                        onClick={() => setShowDeniedReason(true)}
                        // disabled={loading}
                        >
                           {/* {loading ? 'Rejecting...' : 'Reject'} */}
                          Reject
                        </Button>
                      </div>
                    </div>
                  )}

                  {showDeniedReason && (
                    <div className="mt-3">
                      <Button
                        onClick={() =>
                          handleKycSubmit(row.mobileNumber, "Reject")
                        }
                        // disabled={loading}
                      >
                        {/* {loading ? 'Submitting...' : 'Submit'} */}
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              </TableCell>
            </Table>
          </DialogContent>
        </Dialog>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ paddingLeft: 15, margin: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Other Data :
              </Typography>
              {row.deniedReason ? (
                <div className="d-flex gap-5 mt-3">
                  <div className="fw-bold">
                    Denied Reasons:
                    {row.deniedReason.map((reason, index) => (
                      <div key={index}>{`Reason ${index + 1}: ${reason}`}</div>
                    ))}
                  </div>
                </div>
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </React.Fragment>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = addLanguage?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    const token = cookies.get("token");
    axios
      .delete(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/language",
        {
          data: selected,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          getData();
          setSelected([]);
          swal("", response.data.message, "success");
        }
      });
  };

  //
  // Searchbar
  let handleSearchData = async (values) => {
    let res = await axios.post(
      "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/mlm/payment_pending/search",
      {
        search: values,
      },
    );
    if (res.data.statusCode === 200) {
      if (values !== "") {
        setAddLanguage(res.data.data);
        setCountData(res.data.count)
      } else {
        getData();
      }
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();
  // if (!id) {
  //   var handleSubmit = async (values) => {
  //     const token = "your_jwt_token_here";
  //     let res = await axios.post(
  //       "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/language",
  //       values
  //     );
  //     if (res.data.statusCode === 200) {
  //       setModalShowForPopupForm(false);
  //       getData();
  //       swal("", res.data.message, "success");
  //     } else {
  //       swal("", res.data.message, "error");
  //     }
  //   };
  // } else {
  //   handleSubmit = async (values) => {
  //     let response = await axios.put(
  //       "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/language" + id,
  //       values
  //     );

  //     if (response.data.statusCode === 200) {
  //       setModalShowForPopupForm(false);
  //       getData();
  //       swal("", response.data.message, "success");
  //     }
  //   };
  // }

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      const token = cookies.get("token"); // Replace with your actual JWT token
      try {
        const res = await axios.post(
          "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/language",
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          swal("", res.data.message, "success");
        } else {
          swal("", res.data.message, "error");
        }
      } catch (error) {
        console.error("Error:", error);
        swal("", "An error occurred while processing your request.", "error");
      }
    };
  } else {
    handleSubmit = async (values) => {
      const token = cookies.get("token"); // Replace with your actual JWT token

      try {
        const response = await axios.put(
          `https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/language/language/${id}`, // Use template literals to include the id
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          swal("", response.data.message, "success");
        }
      } catch (error) {
        console.error("Error:", error);
        swal("", "An error occurred while processing your request.", "error");
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };

  const [accessType, setAccessType] = React.useState(null);
  let cookies = new Cookies();

  React.useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setAccessType(decodedToken);
      } catch (error) {
        // Handle error if token decoding fails
        console.error("Failed to decode token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <UserSidebar />

      <Box sx={{ width: "100%", pb: "2%", pl: "2%", pr: "2%" }}>
        {/* <div id="main-btn-add-machinetype">
          <div className="d-flex flex-row justify-content-end mb-2">
            <Button
              className="text-capitalize"
              size="small"
              onClick={() => {
                setModalShowForPopupForm(true);
                setId(null);
                setEditData({});
              }}
            >
              Add Language
            </Button>
          </div>
        </div> */}

        <div>
          <div className="d-flex flex-row justify-content-start mb-2 gap-2">
            <div>
              <Button
                className="text-capitalize"
                size="small"
                onClick={() => navigate("/mlm-pending")}
                disabled={true}
              >
                Pending
              </Button>
            </div>
            <div>
              <Button
                className="text-capitalize"
                size="small"
                onClick={() => navigate("/mlm-reject")}
              >
                Reject
              </Button>
            </div>

            <div>
              <Button
                className="text-capitalize"
                size="small"
                onClick={() => navigate("/mlm-complete")}
              >
                Complete
              </Button>
            </div>
          </div>
        </div>

        <Paper sx={{ width: "100%" }} className="table_bg_img">
          <Toolbar
            className="border-top border-bottom"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Payment History
              </Typography>
            )}
            <form className="form-inline">
              <input
                id="serchbar-size"
                className="form-control mr-sm-2"
                type="search"
                onChange={(e) => handleSearchData(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </form>

            {/* {accessType &&
              (() => {
                if (!accessType.includes("Allow To Delete")) {
                  return null;
                }
                return ( */}
            <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
            {/* );
              })()} */}
          </Toolbar>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>

                    {/* {accessType &&
                      (() => {
                        if (!accessType.includes("Allow To Delete")) {
                          return null;
                        }
                        return ( */}
                    {/* <TableCell align="center" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < addLanguage?.length
                        }
                        checked={
                          addLanguage?.length > 0 &&
                          selected.length === addLanguage?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell> */}
                    {/* );
                      })()} */}

                    {headCells.map((headCell, id) => {
                      return (
                        <TableCell key={id} className="fw-bold" align="center">
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addLanguage?.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Row
                        key={row.machineTypeId}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        selected={selected}
                        index={index}
                        seletedEditData={seletedEditData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={countData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </Box>
      <Dialog
        fullWidth
        open={modalShowForPopupForm}
        onClose={() => setModalShowForPopupForm(false)}
      >
        <DialogTitle>{"Language Form"}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              languageName:
                editData && editData.languageName ? editData.languageName : "",
            }}
            validationSchema={Yup.object().shape({
              languageName: Yup.string().required("Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm(values);
            }}
          >
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <Form>
                {/* <FormikValues /> */}
                <div>
                  <div className="mt-3">
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Add Language *"
                      label="Add Language *"
                      name="languageName"
                      value={values.languageName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.languageName && errors.languageName ? (
                      <div className="text-danger">{errors.languageName}</div>
                    ) : null}
                  </div>

                  {!id ? (
                    <Button className="mt-3" type="submit" variant="primary">
                      Add Language
                    </Button>
                  ) : (
                    <Button className="mt-3" type="submit" variant="warning">
                      Update Language
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
