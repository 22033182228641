import React from "react";
import "./NotFound.css";

function MyComponent() {
  return (
    <div className="container">
      <p className="wrong-para">Uh Oh! Page not found!</p>
    </div>
  );
}

export default MyComponent;