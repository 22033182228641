    import React from 'react'
    
    function CreateVisitingCard() {
      return (
        <div style={{ width: "100vw", height: "100vh" }}>
        {/* <Sidebar /> */}
        <iframe
          src="https://visiting-card-v2.vercel.app/" // Replace with the actual URL of your Canva_Projects
          title="Canva Editor"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      )
    }
    
    export default CreateVisitingCard