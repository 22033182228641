import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UserSidebar from "../UserSidebar";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { RotatingLines } from "react-loader-spinner";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { imagePath } from "../ImageVariableGlobal/ImageVariableGlobal";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container, Col } from "react-bootstrap";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Plan Name",
  },
  {
    label: "Plan Price",
  },
  {
    label: "Created At",
  },
  // {
  //   label: "Updated At",
  // },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);
  let cookies = new Cookies();
  let navigate = useNavigate();
  // let navigate = useNavigate();

  const [accessType, setAccessType] = React.useState(null);

  React.useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setAccessType(decodedToken);
      } catch (error) {
        // Handle error if token decoding fails
        console.error("Failed to decode token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row._id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row?.machineTypeId}
        selected={isItemSelected}
      >
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="center" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>

        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="center">{row?.plan_name}</TableCell>
        {/* <TableCell align="center">{row.bannerImage}</TableCell> */}
        <TableCell align="center">{row?.plan_price}</TableCell>

        <TableCell align="center">{row?.createdAt}</TableCell>
        {/* <TableCell align="center">{row?.updatedAt}</TableCell> */}

        {/* <TableCell align="center">
          <button
            className="btn btn-default"
            onClick={() => seletedEditData(row)}
          >
            <EditIcon />
          </button>
        </TableCell> */}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          {/* <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ paddingLeft: 15, margin: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Other Data :
              </Typography>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2px solid black",
                        }}
                      >
                        Field
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2px solid black",
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(row).map(([key, value], index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          {key}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          {typeof value === "boolean"
                            ? value
                              ? "✔️"
                              : "❌"
                            : value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse> */}

          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2, margin: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Other Data:
              </Typography>
              <Paper sx={{ padding: 2 }}>
                <div className="d-flex gap-5">
                  <div>
                    <TableContainer>
                      <Table style={{ minWidth: 650 }}>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Plan ID
                            </TableCell>
                            <TableCell align="center">{row?.plan_id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Plan Name
                            </TableCell>
                            <TableCell align="center">
                              {row?.plan_name}
                            </TableCell>
                          </TableRow>
                          {/* Add more rows for other fields */}
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Plan Price
                            </TableCell>
                            <TableCell align="center">
                              {row?.plan_price}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Business Member
                            </TableCell>
                            <TableCell align="center">
                              {row?.business_member}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Visiting Card
                            </TableCell>
                            <TableCell align="center">
                              {row?.visiting_card}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Percentage
                            </TableCell>
                            <TableCell align="center">
                              {row?.percentage}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Default Frame
                            </TableCell>
                            <TableCell align="center">
                              {row?.default_frame}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Custome Frame
                            </TableCell>
                            <TableCell align="center">
                              {row?.custome_frame}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Business Member
                            </TableCell>
                            <TableCell align="center">
                              {row?.business_member}
                            </TableCell>
                          </TableRow>
                          {/* Add more rows for other boolean fields */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div>
                    <TableContainer>
                      <Table style={{ minWidth: 650 }}>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Festival Image
                            </TableCell>
                            <TableCell align="center">
                              {row.festival_image ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Festival Video
                            </TableCell>
                            <TableCell align="center">
                              {row.festival_video ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Logo
                            </TableCell>
                            <TableCell align="center">
                              {row.logo ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Category Image
                            </TableCell>
                            <TableCell align="center">
                              {row.category_image ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Category Video
                            </TableCell>
                            <TableCell align="center">
                              {row.category_video ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Business Image
                            </TableCell>
                            <TableCell align="center">
                              {row.business_image ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Custome Categories
                            </TableCell>
                            <TableCell align="center">
                              {row.custome_categories ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              A4-Image/Video
                            </TableCell>
                            <TableCell align="center">
                              {row.a4_image_video ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Trnding Image
                            </TableCell>
                            <TableCell align="center">
                              {row.trnding_image ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Remove Bg
                            </TableCell>
                            <TableCell align="center">
                              {row.remove_bg ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              Refer & Earn
                            </TableCell>
                            <TableCell align="center">
                              {row.refer_and_earn ? "✔️" : "❌"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Plan() {
  let navigate = useNavigate();

  const [planData, setPlanData] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let getData = async () => {
    let res = await axios.get("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/plan", {
      params: {
        pageSize: rowsPerPage,
        pageNumber: page,
      },
    });
    setLoader(false);
    setPlanData(res.data.data);
    setCountData(res.data.count);
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = planData.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        axios
          .post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/planData/delete_planData", selected)
          .then((response) => {
            if (response.data.statusCode === 200) {
              getData();
              setSelected([]);
              swal("", response.data.message, "success");
            }
          });
      }
    });
  };

  //
  // Searchbar
  let handleSearchData = async (values) => {
    let res = await axios.post(
      "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/planData/search_planData",
      {
        search: values,
      }
    );
    if (res.data.statusCode === 200) {
      if (values !== "") {
        setPlanData(res.data.data);
        setCountData(res.data.count);
      } else {
        getData();
      }
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();
  if (!id) {
    var handleSubmit = async (values) => {
      values["updateDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["createDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["createTime"] = moment(new Date()).format("HH:mm:ss");
      // values["bannerImage"] = image;
      let res = await axios.post("https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/plan", values);
      if (res.data.statusCode === 200) {
        setModalShowForPopupForm(false);
        getData();
        swal("", res.data.message, "success");
      } else {
        swal("", res.data.message, "error");
      }
    };
  } else {
    handleSubmit = async (values) => {
      values["updateDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["updateDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["updateTime"] = moment(new Date()).format("HH:mm:ss");
      // values["bannerImage"] = image;
      let response = await axios.put(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/planData/planData/" + id,
        values
      );

      if (response.data.statusCode === 200) {
        setModalShowForPopupForm(false);
        getData();
        swal("", response.data.message, "success");
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in form when i edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };

  const [accessType, setAccessType] = React.useState(null);
  let cookies = new Cookies();

  React.useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        setAccessType(decodedToken);
      } catch (error) {
        // Handle error if token decoding fails
        console.error("Failed to decode token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // Log the "CanvaImageIdForEdit" value whenever the state changes
  // React.useEffect(() => {
  //   if (getCanvaCreatedId.length > 0) {
  //     console.log(getCanvaCreatedId[0].CanvaImageIdForEdit);
  //   }
  // }, [getCanvaCreatedId]);

  const [data, setData] = useState({
    startingDate: "",
    endingDate: "",
  });

  const updateInputs = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const filterDate = async () => {
    if (data.startingDate && data.endingDate) {
      // Format the dates to match the database format
      const startingDateFormatted = moment(data.startingDate).format(
        "DD-MM-YYYY"
      );
      const endingDateFormatted = moment(data.endingDate).format("DD-MM-YYYY");

      let response = await axios.post(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/planData/filterData/",
        {
          startingDate: startingDateFormatted,
          endingDate: endingDateFormatted,
        }
      );

      if (response.data.statusCode === 200) {
        setPlanData(response.data.findByDateWisenData);
        setCountData(response.data.totalCount);
      }
    }
  };
  React.useEffect(() => {
    if (data.startingDate && data.endingDate) {
      filterDate();
    }
  }, [data.startingDate, data.endingDate]);

  // -----------------------------Default Banner Logic--------------------------------------

  let [defaultBannerPopupForm, setDefaultBannerPopupForm] =
    React.useState(false);

  let [editDataDefaultImage, setEditDataDefaultImage] = React.useState({});

  let [defaultBannerId, setDefaultBannerId] = useState(null);
  if (!defaultBannerId) {
    var handleDefaultSubmit = async (values) => {
      values["createDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["createTime"] = moment(new Date()).format("HH:mm:ss");
      // values["defaultBannerImage"] = image;
      let res = await axios.post(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/default_banner/default_banner",
        values
      );
      if (res.data.statusCode === 200) {
        setDefaultBannerId(false);
        getData();
        swal("", res.data.message, "success");
      } else {
        swal("", res.data.message, "error");
      }
    };
  } else {
    handleDefaultSubmit = async (values) => {
      values["updateDate"] = moment(new Date()).format("DD-MM-YYYY");
      values["updateTime"] = moment(new Date()).format("HH:mm:ss");
      // values["defaultBannerImage"] = image;
      let response = await axios.put(
        "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/default_banner/default_banner/" +
          defaultBannerId,
        values
      );

      if (response.data.statusCode === 200) {
        setDefaultBannerId(false);
        setDefaultBannerPopupForm(false);
        getDefaultAdvertiseBanner();
        getData();
        swal("", response.data.message, "success");
      }
    };
  }

  let selectedDefaultBannerEditData = async (datass) => {
    console.log(datass, "datass");
    setEditDataDefaultImage(datass._id);
    setEditDataDefaultImage(datass);
    setDefaultBannerPopupForm(true);
    setDefaultBannerId(datass._id);
  };

  let [defaultAdvertiseBanner, setDefaultAdvertiseBanner] = useState();

  let getDefaultAdvertiseBanner = async () => {
    let responce = await axios.get(
      "https://brandingprofitable-uat-89aac59cca6c.herokuapp.com/default_banner/main_banner_default"
    );
    setDefaultAdvertiseBanner(responce.data.data);
  };

  React.useEffect(() => {
    getDefaultAdvertiseBanner();
  }, []);

  return (
    <>
      <UserSidebar />

      <Box sx={{ width: "100%", pb: "2%", pl: "2%", pr: "2%" }}>
        <div id="main-btn-add-machinetype">
          <div className="d-flex flex-row justify-content-end mb-2">
            <Button
              className="text-capitalize"
              size="small"
              onClick={() => {
                setModalShowForPopupForm(true);
                setId(null);
                setEditData({});
              }}
            >
              Add Plan
            </Button>
          </div>
        </div>

        <Paper sx={{ width: "100%" }} className="table_bg_img">
          <Toolbar
            className="border-top border-bottom"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Plans
              </Typography>
            )}

            {/* <form className="form-inline">
              <input
                id="serchbar-size"
                className="form-control mr-sm-2"
                type="search"
                onChange={(e) => handleSearchData(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </form> */}

            <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          </Toolbar>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>

                    <TableCell align="center" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < planData?.length
                        }
                        checked={
                          planData?.length > 0 &&
                          selected.length === planData?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>

                    {headCells.map((headCell, id) => {
                      return (
                        <TableCell key={id} className="fw-bold" align="center">
                          {headCell.label}
                        </TableCell>
                      );
                    })}

                    {/* <TableCell className="fw-bold" align="center">
                      Action
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {planData?.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Row
                        key={row?.machineTypeId}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        selected={selected}
                        index={index}
                        seletedEditData={seletedEditData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={countData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </Box>
      <Dialog
        fullWidth
        open={modalShowForPopupForm}
        onClose={() => setModalShowForPopupForm(false)}
      >
        <DialogTitle>{"Main Banner Form"}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              plan_name:
                editData && editData.plan_name ? editData.plan_name : "",

              plan_price:
                editData && editData.plan_price ? editData.plan_price : "",
              visiting_card:
                editData && editData.visiting_card
                  ? editData.visiting_card
                  : "",
              percentage:
                editData && editData.percentage ? editData.percentage : "",
              default_frame:
                editData && editData.default_frame
                  ? editData.default_frame
                  : "",
              custome_frame:
                editData && editData.custome_frame
                  ? editData.custome_frame
                  : "",

              festival_image:
                editData && editData.festival_image
                  ? editData.festival_image
                  : false,

              festival_video:
                editData && editData.festival_video
                  ? editData.festival_video
                  : false,
              logo: editData && editData.logo ? editData.logo : false,
              category_image:
                editData && editData.category_image
                  ? editData.category_image
                  : false,
              category_video:
                editData && editData.category_video
                  ? editData.category_video
                  : false,
              business_image:
                editData && editData.business_image
                  ? editData.business_image
                  : false,
              custome_categories:
                editData && editData.custome_categories
                  ? editData.custome_categories
                  : false,
              trnding_image:
                editData && editData.trnding_image
                  ? editData.trnding_image
                  : false,
              a4_image_video:
                editData && editData.a4_image_video
                  ? editData.a4_image_video
                  : false,
              remove_bg:
                editData && editData.remove_bg ? editData.remove_bg : false,
              refer_and_earn:
                editData && editData.refer_and_earn
                  ? editData.refer_and_earn
                  : false,
            }}
            validationSchema={Yup.object().shape({
              plan_name: Yup.string().required("Required"),
              plan_price: Yup.number().required("Required"),
              business_member: Yup.number().required("Required"),
              visiting_card: Yup.number().required("Required"),
              percentage: Yup.number().required("Required"),
              default_frame: Yup.number().required("Required"),
              custome_frame: Yup.number().required("Required"),

              // bannerSwitch: Yup.boolean(),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm(values);
              console.log(values, "values");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                {/* <FormikValues /> */}
                <div>
                  <div className="mt-3">
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Plan Name *"
                      label="Plan Name *"
                      name="plan_name"
                      value={values.plan_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.plan_name && errors.plan_name ? (
                      <div className="text-danger">{errors.plan_name}</div>
                    ) : null}
                  </div>

                  {/* Plan price */}
                  <div className="mt-3">
                    <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="Plan Price *"
                      label="Plan Price *"
                      name="plan_price"
                      value={values.plan_price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.plan_price && errors.plan_price ? (
                      <div className="text-danger">{errors.plan_price}</div>
                    ) : null}
                  </div>

                  <div className="d-flex gap-3">
                    <div className="mt-3">
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        placeholder="Business Member *"
                        label="Business Member *"
                        name="business_member"
                        value={values.business_member}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.business_member && errors.business_member ? (
                        <div className="text-danger">
                          {errors.business_member}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <TextField
                        type="number"
                        size="small"
                        fullWidth
                        placeholder="Visiting card *"
                        label="Visiting card *"
                        name="visiting_card"
                        value={values.visiting_card}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.visiting_card && errors.visiting_card ? (
                        <div className="text-danger">
                          {errors.visiting_card}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <TextField
                        type="number"
                        size="small"
                        fullWidth
                        placeholder="Percentage *"
                        label="Percentage *"
                        name="percentage"
                        value={values.percentage}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.percentage && errors.percentage ? (
                        <div className="text-danger">{errors.percentage}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-5">
                  <div className="mt-3">
                    <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="Default Frame *"
                      label="Default Frame *"
                      name="default_frame"
                      value={values.default_frame}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.default_frame && errors.default_frame ? (
                      <div className="text-danger">{errors.default_frame}</div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="Custome Frame *"
                      label="Custome Frame *"
                      name="custome_frame"
                      value={values.custome_frame}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.custome_frame && errors.custome_frame ? (
                      <div className="text-danger">{errors.custome_frame}</div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="d-flex">
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Festival Image"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Festival Video"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>

                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Logo"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div className="d-flex">
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Category Image"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Category Video"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Business Image"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div className="d-flex">
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Custome Categories"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Trending Images"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="A4 Image/Video"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div className="d-flex">
                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Remove bg"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>

                  <FormControl component="fieldset" className="mt-2">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={<Switch color="primary" />}
                        label="Refer & Earn"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                </div> */}
                <div className="mt-4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="festival_image"
                              value={values.festival_image}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Festival Image"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="festival_video"
                              value={values.festival_video}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Festival Video"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="logo"
                              value={values.logo}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Logo"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="category_image"
                              value={values.category_image}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Category Image"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="category_video"
                              value={values.category_video}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Category Video"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="business_image"
                              value={values.business_image}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Business Image"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="custome_categories"
                              value={values.custome_categories}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Custome Categories"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="trnding_image"
                              value={values.trnding_image}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Trending Images"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="a4_image_video"
                              value={values.a4_image_video}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="A4 Image/Video"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="remove_bg"
                              value={values.remove_bg}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Remove bg"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ minWidth: "150px" }}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              name="refer_and_earn"
                              value={values.refer_and_earn}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Refer & Earn"
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="mt-3">
                  {!id ? (
                    <Button className="mt-3" type="submit" variant="primary">
                      Add
                    </Button>
                  ) : (
                    <Button className="mt-3" type="submit" variant="warning">
                      Update
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
