import React from "react";

function A4CanvaLink() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* <Sidebar /> */}
      <iframe
        src="https://a4-frmae-v2.vercel.app/"
        title="Canva Editor"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}

export default A4CanvaLink;
